import { Carousel } from "@trendyol-js/react-carousel";
import React, { useContext, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import acc from "../../../images/natadana/acc.png";
import bfi from "../../../images/natadana/bfi.png";
import myb from "../../../images/natadana/maybank.jpg";
import wom from "../../../images/natadana/wom.jpg";
import bcafImage from "../../../images/natadana/bcaf.png";
import batumbuImage from "../../../images/natadana/batumbu.png";
import danaiImage from "../../../images/natadana/danai.png";

import { ThemeContext } from "../../../context/ThemeContext";
import InvoiceCard from "./rekap_data";
import InvoiceCardBfi from "./rekap_data_bfi";
import InvoiceCardMyb from "./rekap_data_myb";
import InvoiceCardWom from "./rekap_data_wom";
// import InvoiceCardBatumbu from "./rekap_data_batumbu";
import { RekapDataBcaf } from "./rekap_data_bcaf";
import { RekapDataBatumbu } from "./rekap_data_batumbu";
import { RekapDataDanai } from "./rekap_data_danai";

const HomeDark = () => {
  const { changeBackground, chnageSidebarColor } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "dark", label: "Dark" });
  }, []);

  return (
    <>
      <Carousel
        show={1}
        slide={1}
        transition={1.5}
        autoSwipe={60000}
        swiping={true}
        a11y={false}
        swipeOn={0.5}
        rightArrow={false}
        leftArrow={false}
      >
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img src={acc} className="rounded" alt="" />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCard />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img src={bfi} className="rounded" alt="" />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCardBfi />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={wom}
                className="rounded"
                alt=""
                width={366}
                height={120}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCardWom />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={myb}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCardMyb />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={bcafImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <RekapDataBcaf />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={batumbuImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <RekapDataBatumbu />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={danaiImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <RekapDataDanai />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Carousel>
      ,
    </>
  );
};
export default HomeDark;
