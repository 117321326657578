import React from "react";
import useSWR from "swr";

export function RekapDataBcaf() {
  //start
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const api1 = "https://fortress.bcaf.nda.co.id/api/rangkuman_data";
  const { data, error, isLoading } = useSWR(api1, fetcher, {
    refreshInterval: 2000,
    refreshWhenHidden: true,
    refreshWhenOffline: true,
  });
  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  return (
    <>
      <div className="row">
        <div className="col-xl-4 col-xxl-4 col-sm-4">
          <div className="card bg-orange invoice-card">
            <div className="card-body d-flex">
              <div className="icon me-3">
                <i className="fa fa-upload font-18 align-middle fa-2x"></i>
              </div>
              <div>
                <h2 className="text-white invoice-num">{data["terupload"]}</h2>
                <span className="text-black1 fs-25 fw-bold">PENDING DATA</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-xxl-4 col-sm-4">
          <div className="card bg-orange invoice-card">
            <div className="card-body d-flex">
              <div className="icon me-3">
                <i className="fa fa-file-text font-18 align-middle fa-2x"></i>
              </div>
              <div>
                <h2 className="text-white invoice-num">{data["teruploadA"]}</h2>
                <span className="text-black1 fs-25 fw-bold">
                  DOCUMENT READY
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-xxl-4 col-sm-4">
          <div className="card bg-orange invoice-card">
            <div className="card-body d-flex">
              <div className="icon me-3">
                <i className="fa fa-check-square font-18 align-middle fa-2x"></i>
              </div>
              <div>
                <h2 className="text-white invoice-num">{data["lengkap"]}</h2>
                <span className="text-black1 fs-25 fw-bold">
                  COMPLETED DATA
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-xxl-4 col-sm-4">
          <div className="card bg-orange invoice-card">
            <div className="card-body d-flex">
              <div className="icon me-3">
                <i className="fa fa-code font-18 align-middle fa-2x"></i>
              </div>
              <div>
                <h2 className="text-white invoice-num">{data["proses"]}</h2>
                <span className="text-black1 fs-25 fw-bold">PROCESS DATA</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-xxl-4 col-sm-4">
          <div className="card bg-orange invoice-card">
            <div className="card-body d-flex">
              <div className="icon me-3">
                <i className="fa fa-check-square font-18 align-middle fa-2x"></i>
              </div>
              <div>
                <h2 className="text-white invoice-num">{data["selesai"]}</h2>
                <span className="text-black1 font-weight-bold fs-25 fw-bold">
                  SJF ISSUED
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-xxl-4 col-sm-4">
          <div className="card bg-orange invoice-card">
            <div className="card-body d-flex">
              <div className="icon me-3">
                <i className="fa fa-file-text font-18 align-middle fa-2x"></i>
              </div>
              <div>
                <h2 className="text-white invoice-num">{data["total"]}</h2>
                <span className="text-black1 fs-25 fw-bold">TOTAL DATA</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}